import { catalogRoutes } from '@/modules/catalog/mock/catalogRoutes.js'
import { farmRoutes } from '@/services/__mocks__/farmRoutes'
import { fieldRoutes } from '@/modules/plot/mock/fieldRoutes.js'
import { menuRoutes } from '@/modules/menu/mock/menuRoutes.js'
import { noteRoutes } from '@/modules/notes/mock/noteRoutes.js'
import { sowingRoutes } from '@/modules/sowing/mock/routes.js'
import { paymentRoutes } from '@/modules/payment/mock/paymentRoutes.js'
import { varietyRoutes } from '@/pages/cultivares/mock/varietyRoutes.js'

const routes = [
  ...catalogRoutes,
  ...farmRoutes,
  ...fieldRoutes,
  ...menuRoutes,
  ...noteRoutes,
  ...paymentRoutes,
  ...sowingRoutes,
  ...varietyRoutes,
]

function globalAPIFailureWrapper(content, failureRate = 0) {
  const chance = Math.floor(Math.random() * 100)
  if (failureRate > chance) {
    throw new Error('Falha por conta causa do wrapper global do mock')
  }
  return content
}

export const mockServer = {
  models: {},

  routes() {
    this.urlPrefix = 'http://localhost'
    this.namespace = 'api'
    this.passthrough('https://maps.googleapis.com/**')
    this.passthrough('https://viacep.com.br/**')
    this.passthrough(`${process.env.VUE_APP_VINDI_URL}/**`)
    this.passthrough()

    routes.forEach((route) => {
      this[route.method](route.url, (_, request) =>
        globalAPIFailureWrapper(route.result(request), 0)
      )
    })
  },

  seeds() {},
}
